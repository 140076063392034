<template>
  <div class="container">
    <h1>Bachata Library <span>by Max</span></h1>
    <div class="moves-grid">
      <div
        v-for="(move, index) in moves"
        :key="index"
        class="move-item"
        @click="openPreview(index)"
      >
        <img :src="getImageUrl(move)" :alt="`Bachata move ${index + 1}`" />
      </div>
    </div>

    <!-- Full-screen preview -->
    <div
      v-if="previewIndex !== null"
      class="preview-overlay"
      @click="closePreview"
    >
      <div class="preview-content" @click.stop>
        <img
          :src="getImageUrl(moves[previewIndex])"
          :alt="`Bachata move ${previewIndex + 1}`"
        />
        <button
          class="nav-button prev"
          @click.stop="navigatePreview(-1)"
          :disabled="previewIndex === 0"
        >
          &lt;
        </button>
        <button
          class="nav-button next"
          @click.stop="navigatePreview(1)"
          :disabled="previewIndex === moves.length - 1"
        >
          &gt;
        </button>
        <button class="close-button" @click.stop="closePreview">×</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';

export default {
  setup() {
    const moves = ref([]);
    const previewIndex = ref(null);

    const getImageUrl = (filename) => {
      return `https://bachata-assets.maxstr.se/2024-08-09/${filename}`;
    };

    const fetchMoves = async () => {
      try {
        const response = await fetch(
          'https://bachata-assets.maxstr.se/2024-08-09/filelist.json'
        );
        if (!response.ok) {
          throw new Error('Failed to fetch move list');
        }
        const data = await response.json();
        moves.value = data.files;
      } catch (error) {
        console.error('Error fetching moves:', error);
      }
    };

    const openPreview = (index) => {
      previewIndex.value = index;
      document.addEventListener('keydown', handleKeyDown);
    };

    const closePreview = () => {
      previewIndex.value = null;
      document.removeEventListener('keydown', handleKeyDown);
    };

    const navigatePreview = (direction) => {
      previewIndex.value += direction;
      if (previewIndex.value < 0) previewIndex.value = moves.value.length - 1;
      if (previewIndex.value >= moves.value.length) previewIndex.value = 0;
    };

    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') navigatePreview(-1);
      if (event.key === 'ArrowRight') navigatePreview(1);
      if (event.key === 'Escape') closePreview();
    };

    onMounted(() => {
      fetchMoves();
    });

    onUnmounted(() => {
      document.removeEventListener('keydown', handleKeyDown);
    });

    return {
      moves,
      getImageUrl,
      previewIndex,
      openPreview,
      closePreview,
      navigatePreview,
    };
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600&display=swap');

body {
  font-family: 'Inter', sans-serif;
  background-color: #f4f4f8;
  color: #333;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

h1 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2.5rem;
  font-weight: 600;
  color: #1a1a2e;
  text-transform: uppercase;
  letter-spacing: 1px;
}

h1 span {
  display: block;
  font-size: 1.5rem;
  font-weight: 300;
  color: #16213e;
  text-transform: none;
  letter-spacing: normal;
}

.moves-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 30px;
}

.move-item {
  aspect-ratio: 9 / 16;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.move-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.move-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.move-item:hover img {
  transform: scale(1.05);
}

.preview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.preview-content {
  position: relative;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-content img {
  height: 100%;
  width: auto;
  max-width: 100vw;
  object-fit: contain;
}

.nav-button,
.close-button {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
  font-size: 24px;
  padding: 15px;
  cursor: pointer;
  z-index: 1010;
  transition: background-color 0.3s ease;
  border-radius: 50%;
}

.nav-button:hover,
.close-button:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.nav-button {
  top: 50%;
  transform: translateY(-50%);
}

.nav-button.prev {
  left: 20px;
}

.nav-button.next {
  right: 20px;
}

.close-button {
  top: 20px;
  right: 20px;
  font-size: 30px;
  padding: 10px 18px;
}
</style>
